import { api_listUserInfo, api_listUsers } from "@/api/children/listData"

const userCache: {
    [userId: string]: {
        id: string;
        GuildnUserKey: string;
        username: string;
        nickname: string;
        avatar: string;
        data: any;
    }
} = {

}

export const getUsersList = async (guildId: string) => {
    const data = await api_listUsers(guildId)

    for (let user of data.data) {
        userCache[user.id] = user
    }

    return data.data
}
export const getUser = async (userId: string) => {
    if (userCache[userId]) {
        return userCache[userId]
    }
    const data = await api_listUserInfo(userId)
    const user = data.data.pop()
    if(user){
        const userFix: typeof user = {
            ...user,
            nickname: decodeURIComponent(user.nickname),
            username: decodeURIComponent(user.username)
        }
        return userFix
    }
   
    return user
}