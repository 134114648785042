
import { api_freshUser } from '@/api/children/freshData';
import { api_listUsers, api_listVoiceChatBoard } from '@/api/children/listData';
import { User } from '@/user';
import { Avatar, Button, Drawer, Image, message, Popover, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { VoiceChatBoard } from '../Schedule';
import { ListVoteDict } from '../Vote';
import {
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import "./index.less";
import { api_editUserCoinHB, api_editUserExtraInfo } from '@/api/children/editData';


type UsersProps = {
    shortName?: string
    guildId: string
    user: User
}
type ActivitysState = {
    items?: DataType[]
    manageingActivityId?: number
    manageingActivityshortName?: string
    checkBoard?: boolean
    checkVoteDict?: boolean
}
interface DataType {
    lineId: number;
    userId: string;
    GuildnUserKey: string
    key: React.Key;
    username: string
    nickname: string
    avatar: string
    coinHB: number
    extraInfo: string
}


export class Users extends PureComponent<UsersProps, ActivitysState> {
    constructor(props: UsersProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div className='Users'>
            <Button type="primary" style={{ margin: 10, }} onClick={this.onFresh.bind(this)}>{"下载用户数据"}</Button>
            <Button style={{ margin: 10, }} onClick={this.checkVoiceChatBoard.bind(this)}>{"查看语音频道活跃数据"}</Button>
            <Button style={{ margin: 10, }} onClick={this.checkVoteDict.bind(this)}>{"管理投票机器人"}</Button>
            <Table
                pagination={{ showSizeChanger: true, position: ["topLeft"], pageSizeOptions: [100, 1000, 10000] }}
                columns={this.columns}
                scroll={{ x: 1000, scrollToFirstRowOnChange: true }}
                dataSource={this.state.items} />
            <Drawer
                title={`活跃数据(${this.props.shortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"95%"}
                onClose={this.onHandleClose.bind(this)}
                open={this.state.checkBoard}>
                {this.state.checkBoard ? <VoiceChatBoard user={this.props.user} /> : ""}
            </Drawer>
            <Drawer
                title={`管理 投票 目录`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={this.onManageClose.bind(this)}
                open={this.state.checkVoteDict}>
                {this.state.checkVoteDict ? <ListVoteDict shortName={this.state.manageingActivityshortName} user={this.props.user} /> : ""}
            </Drawer>

        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: '行',
            dataIndex: 'lineId',
            key: 'lineId',
            width: 48,
        },
        {
            title: '头像',
            className: "avatar",
            dataIndex: 'avatar',
            key: 'avatar',
            width: 48,
            render: (t, item) => <Avatar src={<img
                src={item.avatar} style={{ width: 32 }}
                loading="lazy" referrerPolicy="no-referrer" />} />
        },
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username',
            width: 190,
            render: (data: string) => decodeURI(data)
        },
        {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: 190,
            render: (data: string) => decodeURI(data)
        },
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: 'HB',
            dataIndex: 'coinHB',
            key: 'coinHB',
            render: (t, item) => <span style={{ cursor: "pointer" }}>
                <Popover placement="topLeft" content={<div style={{ width: 300 }}>
                    <div style={{ fontSize: 24 }}>
                        {String(item.coinHB)}
                    </div>

                    <div style={{ marginTop: 12 }}>
                        <Button type="primary" style={{ width: "calc(50% - 5px)", marginRight: 5, marginBottom: 5 }} size="small"
                            onClick={() => { this.setHB(item, item.coinHB - 100) }}>-100</Button>
                        <Button type="primary" style={{ width: "calc(50% - 5px)", marginLeft: 5, marginBottom: 5 }} size="small"
                            onClick={() => { this.setHB(item, item.coinHB + 100) }}>+100</Button>
                        <Button type="primary" style={{ width: "calc(50% - 5px)", marginRight: 5, marginBottom: 5 }} size="small"
                            onClick={() => { this.setHB(item, item.coinHB - 500) }}>-500</Button>
                        <Button type="primary" style={{ width: "calc(50% - 5px)", marginLeft: 5, marginBottom: 5 }} size="small"
                            onClick={() => { this.setHB(item, item.coinHB + 500) }}>+500</Button>

                        <Button type="primary" style={{ width: "100%" }} size="small" onClick={() => { this.setHB(item) }}>自定义</Button>
                    </div>
                </div>} title={decodeURIComponent(item.username)}>
                    <div><Tag style={{ minWidth: 64, textAlign: "center" }}>{item.coinHB || 0}</Tag></div>
                </Popover>
            </span>,
            // width: 155,
        },
        {
            title: '备注',
            dataIndex: 'extraInfo',
            key: 'extraInfo',
            render: (t, item) => <span style={{ cursor: "pointer" }}>
                <Popover placement="topLeft" content={<div style={{ width: 300 }}>
                    {Array.from(item.extraInfo.split(","), (line, lineId) => {
                        if (line) {
                            return <div>
                                <div style={{ width: "calc(100% - 100px)", display: "inline-block" }}>{decodeURIComponent(line)}</div>
                                <div style={{ width: 100, display: "inline-block" }}>
                                    <Button type="text" onClick={() => { this.editExtraInfoLine(item, lineId) }}><EditOutlined /></Button>
                                    <Button type="text" onClick={() => { this.deleteExtraInfoLine(item, lineId, line) }} danger><DeleteOutlined /></Button>
                                </div>
                            </div>
                        }
                    })}
                    <div style={{ marginTop: 12 }}>
                        <Button type="primary" style={{ width: "100%" }} size="small" onClick={() => { this.addExtraInfoLine(item) }}>Add</Button>
                    </div>
                </div>} title={decodeURIComponent(item.username)}>
                    <div>{item.extraInfo ? decodeURIComponent(item.extraInfo) : <Tag color="red" style={{ width: "100%" }}>{"空"}</Tag>}</div>
                </Popover>
            </span>,
            // width: 155,
        },
     
        // {
        //     title: '创建时间',
        //     dataIndex: 'createTime',
        //     key: 'createTime',
        //     render: (t, item) => item.createTime.toLocaleString(),
        // },
        // {
        //     title: '场数',
        //     dataIndex: 'ScheduleCount',
        //     key: 'ScheduleCount',
        //     render: (t, item) => <Tooltip title="点击管理活动场数">
        //         <Button type="dashed" onClick={() => this.manage(item.activityId, item.name)}>{`${item.ScheduleCount} 场`}</Button>
        //     </Tooltip>
        // },
        // {
        //     title: '控制',
        //     key: 'action',
        //     fixed: 'right',
        //     width: 250,
        //     render: (_, item) => (
        //         <Space size="middle">
        //             {/* <a onClick={() => this.rename(item.activityId)}>{"重命名"}</a> */}
        //             {/* <a onClick={() => this.publish(item.activityId, item.published)}>{item.published ? "下架" : "上架"}</a> */}
        //             {/* <a onClick={() => this.delete(item.activityId)}>{"删除"}</a> */}
        //             233
        //         </Space>
        //     ),
        // },
    ];
    async fetchData() {
        const data2 = await api_listVoiceChatBoard(this.props.guildId)
        const data = await api_listUsers(this.props.guildId)
        const items: DataType[] = Array.from(data.data, (item, key) => {
            return {
                ...item,
                lineId: key + 1,
                userId: item.id,
                key: item.GuildnUserKey

            }
        })
        this.setState({ items })
    }
    async onFresh() {
        message.info("正在下载用户数据")
        await api_freshUser(this.props.guildId)
        message.info("数据下载完成")
        await this.fetchData()
    }

    async checkVoiceChatBoard() {
        this.setState({ checkBoard: true })
    }
    async checkVoteDict() {
        this.setState({ checkVoteDict: true })
    }



    async addExtraInfoLine(item: DataType) {
        const GuildnUserKey = item.GuildnUserKey
        const beforeExtraInfo = item.extraInfo
        const newExtraInfo = beforeExtraInfo ? `${beforeExtraInfo},${encodeURIComponent(`默认备注文字`)}` : `${encodeURIComponent(`默认备注文字`)}`


        await api_editUserExtraInfo(GuildnUserKey, newExtraInfo)
        this.fetchData()
    }

    async deleteExtraInfoLine(item: DataType, lineId: number, text: string) {
        const GuildnUserKey = item.GuildnUserKey
        const beforeExtraInfo = item.extraInfo
        const lineIdString = String(lineId)

        if (confirm(`删除id为${lineId}的备注「${decodeURIComponent(text)}」吗`)) {
            const splited = beforeExtraInfo.split(",")
            const newLines: string[] = []
            for (let thisLineid in splited) {
                if (thisLineid !== lineIdString) {
                    newLines.push(splited[thisLineid])
                }
            }
            const newExtraInfo = newLines.join(",")

            await api_editUserExtraInfo(GuildnUserKey, newExtraInfo)
            this.fetchData()
        }

    }

    async editExtraInfoLine(item: DataType, lineId: number,) {
        const GuildnUserKey = item.GuildnUserKey
        const beforeExtraInfo = item.extraInfo
        const lineIdString = String(lineId)


        const splited = beforeExtraInfo.split(",")
        const newLines: string[] = []
        for (let thisLineid in splited) {
            if (thisLineid !== lineIdString) {
                newLines.push(splited[thisLineid])
            } else {
                const newValue = prompt("修改", decodeURIComponent(splited[thisLineid]));
                if (!newValue) {
                    return
                } else {
                    newLines.push(encodeURIComponent(newValue))
                }
            }
        }
        const newExtraInfo = newLines.join(",")

        await api_editUserExtraInfo(GuildnUserKey, newExtraInfo)
        this.fetchData()


    }

    async setHB(item: DataType, newValue?: number) {
        const GuildnUserKey = item.GuildnUserKey
        if(typeof newValue === "undefined"){
            const newValue = prompt(`为${item.username}设置一个新的HB值`, String(item.coinHB));
            if (!newValue) {
                return
            } else {
                const newValueNumber = Number(newValue)
                if(newValueNumber > 0 ||newValueNumber <=0){
                    await api_editUserCoinHB(GuildnUserKey, newValueNumber)
                }else{
                    alert("值无效，已终止操作")
                }

            }
        }else{
            await api_editUserCoinHB(GuildnUserKey, newValue)
        }
        this.fetchData()
    }
    // 
    async manage(activityId: number, name: string) {
        this.setState({
            manageingActivityId: activityId,
            manageingActivityshortName: name,
        })
    }
    async onManageClose() {
        this.fetchData()
        this.setState({
            manageingActivityId: undefined,
            checkVoteDict: undefined
        })
    }
    async onHandleClose() {
        this.fetchData()
        this.setState({ checkBoard: undefined })
    }

}

