import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"


export type ListVoteDataAPIs = APIExample<{
    "/list/vote/data/list": {
        method: "POST"
        toServer: {
            ticket: string
            voteId: number
        },
        toClient: {
            data: {
                list: {
                    id: number;
                    userId: string
                    voteOptionId: number
                    GuildnUserKey: string
                    createTime: Date | string;
                }[],
                options: {
                    id: number;
                    voteId: number;
                    optionName: string
                    optionButtonText: string
                }[]
            }
        }
    },
    // "/add/vote/option": {
    //     method: "POST"
    //     toServer: {
    //         ticket: string
    //         voteId: number
    //         optionName: string
    //     },
    //     toClient: {
    //         data: any
    //     }
    // },
    // "/edit/vote/option": {
    //     method: "POST"
    //     toServer: {
    //         ticket: string
    //         optionId: number

    //         optionName?: string
    //         optionButtonText?: string
    //         enabled?: boolean
    //         removed?: boolean
    //     },
    //     toClient: {
    //         data: any
    //     }
    // },

}>



export const api_listVoteData = async (voteId: number) => {
    return await apiRequest("/list/vote/data/list", {
        ticket: getTicket(),
        voteId,
    })
}

// export const api_addVoteOption = async (data: {
//     voteId: number
//     optionName: string
// }) => {
//     return await apiRequest("/add/vote/option", {
//         ticket: getTicket(),
//         ...data,
//     })
// }


// export const api_renameVoteOption = async (optionId: number, optionName: string) => {
//     return await apiRequest("/edit/vote/option", {
//         ticket: getTicket(),
//         optionId,
//         optionName,

//     })
// }
// export const api_reeditVoteOptionButtonText = async (optionId: number, text: string) => {
//     return await apiRequest("/edit/vote/option", {
//         ticket: getTicket(),
//         optionId,
//         optionButtonText: text,

//     })
// }

// export const api_enableVoteOption = async (optionId: number, enabled: boolean) => {
//     return await apiRequest("/edit/vote/option", {
//         ticket: getTicket(),
//         optionId,
//         enabled,

//     })
// }


// export const api_removedVoteOption = async (optionId: number, conform: "DELETE") => {
//     return await apiRequest("/edit/vote/option", {
//         ticket: getTicket(),
//         optionId,
//         removed: conform === "DELETE",

//     })
// }
