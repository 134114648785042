import { api_listVoiceChatBoard } from "@/api/children/listData"

type BoardData = {
    userId: string;
    channelId: string;
    actions: {
        virtual?: boolean
        action: "joined" | "exit";
        kookTime: string | Date;
    }[];
    logId: number;
}[]

export const getListVoiceChatBoard = async (config?: { startTime?: Date, endTime?: Date }): Promise<BoardData> => {
    const data = await api_listVoiceChatBoard("")

    const startTime = config?.startTime || new Date(0)
    const endTime = config?.endTime || new Date()

    let firstAction: "joined" | "exit" | "" = ""
    let lastAction: "joined" | "exit" | "" = ""
    let lastJoinTime: number = new Date("2042-10-31T16:00:00.000Z").valueOf()
    let lastExitTime: number = 0

    const users = Array.from(data.data, (user) => {
        const actions: (BoardData[number]["actions"][number] | undefined)[] = Array.from(user.actions, (action) => {
            const time = new Date(action.kookTime).valueOf()
            if (action.action === "joined" && time < lastJoinTime) {
                lastJoinTime = time
            } else if (action.action === "exit" && time > lastExitTime) {
                lastExitTime = time
            }
            if (time < endTime.valueOf() && time > startTime.valueOf()) {
                if (!firstAction) {
                    firstAction = action.action
                }
                lastAction = action.action
                return action
            }
        })
        console.log("getListVoiceChatBoard", "lastAction", lastAction)
        if (lastAction === "joined") {
            actions.push({
                action: "exit",
                kookTime: endTime.toISOString(),
                virtual: true,
            })
        }
        if (firstAction === "exit") {
            actions.reverse()
            actions.push({
                action: "joined",
                kookTime: startTime.toISOString(),
                virtual: true,
            })
            actions.reverse()
        }

        const newActions: BoardData[number]["actions"] = []

        for (let action of actions) {
            if (action) {
                newActions.push(action)
            }
        }

        return {
            ...user,
            "actions": newActions,
        }

    })


    return users
}