import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"


export type editDataAPIs = APIExample<{
    "/edit/user": {
        method: "POST"
        toServer: {
            ticket: string
            GuildnUserKey: string
            extraInfo?: string
            coinHB?: number
        },
        toClient: {
            data: any
        }
    },

}>


export const api_editUserCoinHB = async (GuildnUserKey: string, coinHB: number) => {
    return await apiRequest("/edit/user", {
        ticket: getTicket(),
        GuildnUserKey,
        coinHB,
    })
}

export const api_editUserExtraInfo = async (GuildnUserKey: string, extraInfo: string) => {
    return await apiRequest("/edit/user", {
        ticket: getTicket(),
        GuildnUserKey,
        extraInfo,
    })
}
