import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"


export type ListVoteOptionAPIs = APIExample<{
    "/list/vote/option": {
        method: "POST"
        toServer: {
            ticket: string
            voteId: number
        },
        toClient: {
            data: {
                id: number;

                optionName: string
                optionButtonText: string

                createTime: Date | string;
                enabled: boolean;
                removed: boolean;
                data: any
            }[]
        }
    },
    "/add/vote/option": {
        method: "POST"
        toServer: {
            ticket: string
            voteId: number
            optionName: string
        },
        toClient: {
            data: any
        }
    },
    "/edit/vote/option": {
        method: "POST"
        toServer: {
            ticket: string
            optionId: number

            optionName?: string
            optionButtonText?: string
            enabled?: boolean
            removed?: boolean
        },
        toClient: {
            data: any
        }
    },

}>



export const api_listVoteOption = async (voteId: number) => {
    return await apiRequest("/list/vote/option", {
        ticket: getTicket(),
        voteId,
    })
}

export const api_addVoteOption = async (data: {
    voteId: number
    optionName: string
}) => {
    return await apiRequest("/add/vote/option", {
        ticket: getTicket(),
        ...data,
    })
}


export const api_renameVoteOption = async (optionId: number, optionName: string) => {
    return await apiRequest("/edit/vote/option", {
        ticket: getTicket(),
        optionId,
        optionName,

    })
}
export const api_reeditVoteOptionButtonText = async (optionId: number, text: string) => {
    return await apiRequest("/edit/vote/option", {
        ticket: getTicket(),
        optionId,
        optionButtonText: text,

    })
}

export const api_enableVoteOption = async (optionId: number, enabled: boolean) => {
    return await apiRequest("/edit/vote/option", {
        ticket: getTicket(),
        optionId,
        enabled,

    })
}


export const api_removedVoteOption = async (optionId: number, conform: "DELETE") => {
    return await apiRequest("/edit/vote/option", {
        ticket: getTicket(),
        optionId,
        removed: conform === "DELETE",

    })
}
