import { EditOutlined, } from '@ant-design/icons';
import { DatePicker, Input, Modal, Space, Tabs, TimePicker } from 'antd';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import { ChangeEvent, PureComponent } from 'react';
const { confirm } = Modal;
moment.locale('zh-cn');

export const DateTimePicker = (title: string, defaultValue?: Date, infoText?: string) => {
    return new Promise<Date | undefined>((resolve, reject) => {
        let mixedMoment = moment(defaultValue)
        let nextMoment = moment()
        confirm({
            title: title,
            icon: <EditOutlined />,
            content: <div>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={"智能录入"} key="1">
                        <div style={{ paddingTop: 15 }}>
                            <DateTimeSmartInput onChange={(next) => {
                                console.log("DateTimeSmartInput", new Date(next.toISOString()).toLocaleString())
                                nextMoment = next
                            }} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"时间选择器"} key="2">
                        <div style={{ paddingTop: 15 }}>
                            <DateTime time={mixedMoment} onChange={(next) => {
                                console.log("DateTime", new Date(next.toISOString()).toLocaleString())
                                nextMoment = next
                            }} />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
                <div style={{ paddingTop: 15 }}>{infoText}</div>
            </div>,
            onOk() {
                resolve(new Date(nextMoment.toISOString()))
            },
            onCancel() { resolve(undefined) },
        });
    })

}



type DateTimeProps = {
    time: moment.Moment
    onChange: (moment: moment.Moment) => void
}
type DateTimeState = {
    time: moment.Moment
}
export class DateTime extends PureComponent<DateTimeProps, DateTimeState> {
    constructor(props: DateTimeProps) {
        super(props)
        this.state = {
            time: this.props.time
        }
    }
    render() {
        return <>
            <DatePicker
                style={{ marginRight: 5 }}
                locale={zhCN}
                value={this.state.time}
                onChange={(event) => {
                    if (event) {
                        this.setState({ time: event })
                        this.props.onChange(event)
                    }
                }}
            />
            <TimePicker
                locale={zhCN}
                value={this.state.time}
                onChange={(event) => {
                    if (event) {
                        this.setState({ time: event })
                        this.props.onChange(event)
                    }
                }}
            />
        </>

    }

}

type DateTimeSmartInputProps = {
    onChange: (moment: moment.Moment) => void
}
type DateTimeSmartInputState = {
    rawInput?: string
    time?: Date
}
export class DateTimeSmartInput extends PureComponent<DateTimeSmartInputProps, DateTimeSmartInputState> {
    constructor(props: DateTimeSmartInputProps) {
        super(props)
        this.state = {
        }
    }
    render() {
        return <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <div>
                    <Input placeholder="输入字符" onChange={this.onInput.bind(this)} />
                </div>
                {(() => {
                    if (this.state.rawInput) {
                        return <div>
                            <Input placeholder="输入字符" disabled value={this.state.time ? this.state.time.toLocaleString() : "无法识别当前输入"} />
                        </div>
                    }
                })()}
            </Space>
        </>

    }
    onInput(event: ChangeEvent<HTMLInputElement>) {
        let value: string | number = event.target.value
        this.setState({ rawInput: value })

        value = value.replaceAll("年", "/")
        value = value.replaceAll("月", "/")
        value = value.replaceAll("日", " ")
        value = value.replaceAll("AM", " AM")
        value = value.replaceAll("PM", " PM")

        if (Number(value) > 0) {
            value = Number(value)
        }

        const time = new Date(value)
        if (time.valueOf() > 0) {
            this.setState({ time })
        } else {
            this.setState({ time: undefined })
        }
        const MomentType = moment(time)
        this.props.onChange(MomentType)
    }

}

