import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"


export type ListDataAPIs = APIExample<{
    "/list/guild": {
        method: "POST"
        toServer: {
            ticket: string
        },
        toClient: {
            data: {
                id: string;
                name: string;
                createTime: Date;
                removed: boolean;
                data: any
            }[]
        }
    },
    "/list/user": {
        method: "POST"
        toServer: {
            ticket: string
            guildId: string
        },
        toClient: {
            data: {
                id: string;
                GuildnUserKey: string;
                username: string
                nickname: string
                avatar: string
                extraInfo: string 
                coinHB: number 
                data: any
            }[]
        }
    },
    "/list/user/info": {
        method: "POST"
        toServer: {
            ticket: string
            userId: string
        },
        toClient: {
            data: {
                id: string;
                GuildnUserKey: string;
                username: string
                nickname: string
                avatar: string
                data: any
            }[]
        }
    },
    "/list/voiceChat/board": {
        method: "POST"
        toServer: {
            ticket: string
            guildId: string
        },
        toClient: {
            data: {
                userId: string
                channelId: string
                actions: {
                    action: "joined" | "exit"
                    kookTime: Date | string
                }[]
                logId: number
            }[]
        }
    },
}>


export const api_listGuilds = async () => {
    return await apiRequest("/list/guild", {
        ticket: getTicket(),
    })
}

export const api_listUsers = async (guildId: string) => {
    return await apiRequest("/list/user", {
        ticket: getTicket(),
        guildId,
    })
}

export const api_listUserInfo = async (userId: string) => {
    return await apiRequest("/list/user/info", {
        ticket: getTicket(),
        userId,
    })
}

export const api_listVoiceChatBoard = async (guildId: string) => {
    return await apiRequest("/list/voiceChat/board", {
        ticket: getTicket(),
        guildId,
    })
}
