
import { api_listVoiceChatBoard } from '@/api/children/listData';
// import { api_addSchedule, api_autoHideSchedule, api_deleteSchedule, api_editSchedule, api_listSchedule, api_publishSchedule } from '@/api/children/schedule';
import { DateTimePicker } from '@/component/prompt';
import { getListVoiceChatBoard } from '@/function/VoiceChatBoard';
import { User } from '@/user';
import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Drawer, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { PureComponent } from 'react';
import { VoiceChatBoardDataType } from '.';
// import { Seats } from '../Seat';

type VoiceChatBoardProps = {
    shortName?: string
    manageingUserId: string
    user: User
    data: VoiceChatBoardDataType[]
    startTime?: Date
    endTime?: Date
}
type State = {
    items?: DataType[]
    manageingScheduleId?: number
    manageingShortName?: string
}
interface DataType {
    // totalTime: number
    // longestTime: number
    virtual: boolean
    timesId: number
    action: "joined" | "exit"
    kookTime: string | Date
    last?: number
}


export class VoiceChatBoardDetail extends PureComponent<VoiceChatBoardProps, State> {
    constructor(props: VoiceChatBoardProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div>
            {/* <Button type="primary" style={{ margin: 10 }} onClick={this.addSchedule.bind(this)}>{"新建场次"}</Button> */}
            <Table
                pagination={{ showSizeChanger: true }}
                columns={this.columns}
                dataSource={this.state.items}
            // scroll={{
            //     x: 1400
            // }}
            />
            {/* 
            <Drawer
                title={`座位管理(${this.state.manageingShortName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingScheduleId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingScheduleId === "number"}>
                {this.state.manageingScheduleId ? <Seats scheduleId={this.state.manageingScheduleId} user={this.props.user} /> : ""}
            </Drawer> */}


        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: '行',
            dataIndex: 'timesId',
            key: 'timesId',
            width: 48,
        },
        {
            title: '用户ID',
            dataIndex: 'userId',
            key: 'userId',
            render: () => this.props.manageingUserId
        },
        {
            title: '行为',
            dataIndex: 'action',
            key: 'action',
            render: (data: "joined" | "exit") => data === "exit" ? "离开" : "进入",
        },
        {
            title: '时机',
            dataIndex: 'kookTime',
            key: 'kookTime',
            render: (data: string | Date) => new Date(data).toLocaleString()
        },
        {
            title: '持续',
            dataIndex: 'last',
            key: 'last',
            render: (data) => data ? <>{"+"}{this.friendlyTime(data)}</> : "/"
        },
        {
            title: '真实性',
            dataIndex: 'virtual',
            key: 'virtual',
            render: (data, item) => item.virtual ? <Tag color="purple">{"模拟"}</Tag> : <Tag color="green">{"真实"}</Tag>
        },
    ];
    async fetchData() {
        const data = await getListVoiceChatBoard({
            startTime:new Date("2022-10-30T16:00:00.000Z"),
            // startTime:this.props.startTime,
            endTime:new Date("2022-10-31T16:00:00.000Z"),
            // endTime:this.props.endTime,
        })
        console.log("getListVoiceChatBoard")
        // const items: (DataType | undefined)[] = Array.from(this.props.data, (item, key) => {
        const items: (DataType)[] = []
        Array.from(data, (item, key) => {
            let totalTime = 0, timesId = 0

            if (item.userId !== this.props.manageingUserId) {
                return
            }
            let lastActionTime = 0
            let last: number | undefined
            Array.from(item.actions, (action, key) => {
                if (action.action === "joined") {
                    last = undefined
                    lastActionTime = new Date(action.kookTime).valueOf()
                } else if (lastActionTime > 0) {
                    last = (new Date(action.kookTime).valueOf() - lastActionTime) / 1000
                }

                items.push({
                    "virtual": action.virtual || false,
                    "timesId": key + 1,
                    "action": action.action,
                    "kookTime": action.kookTime,
                    last
                })
            })
        })
        this.setState({ items })
    }

    friendlyTime(data?: number) {
        if (typeof data !== "number") { return }
        const hour = Math.floor(data / 3600)
        const minute = Math.floor((data / 60) - 60 * hour)
        const second = Math.floor(data - (hour * 3600) - minute * 60)
        return <>{`${hour}小时${minute}分钟${second}秒`}</>
    }

}

