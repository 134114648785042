
import { api_listUserInfo } from '@/api/children/listData';
import { api_listVoteData } from '@/api/vote/data';
import { api_addVoteOption, api_enableVoteOption, api_listVoteOption, api_reeditVoteOptionButtonText, api_removedVoteOption, api_renameVoteOption } from '@/api/vote/options';
import { getUser } from '@/function/users/fetch';
import { User } from '@/user';
import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PureComponent } from 'react';


type ListVoteOptionsProps = {
    shortName?: string
    user: User
    voteId: number
}
type ListVoteOptionsState = {
    items?: DataType[]
    manageingVoteId?: number
    manageingVoteName?: string
    checkVoteDict?: boolean
}
interface DataType {
    visualId: number;
    id: number;
    optionName: string
    userId: string
    userName: string
    nickName: string
    createTime: Date;
    // enabled: boolean;
    // removed: boolean;
}


export class ListVoteData extends PureComponent<ListVoteOptionsProps, ListVoteOptionsState> {
    constructor(props: ListVoteOptionsProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div className='Users'>
            <Table
                pagination={{ showSizeChanger: true }}
                columns={this.columns}
                dataSource={this.state.items} />

        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 48,
        },
        {
            title: 'visualId',
            dataIndex: 'visualId',
            key: 'visualId',
            width: 48,
        },
        {
            title: 'userId',
            dataIndex: 'userId',
            key: 'userId',
            width: 155,
        },
        {
            title: 'userName',
            dataIndex: 'userName',
            key: 'userName',
            width: 155,
        },
        {
            title: 'nickName',
            dataIndex: 'nickName',
            key: 'nickName',
            width: 155,
        },
        {
            title: '选项内容',
            dataIndex: 'optionName',
            key: 'optionName',
            width: 155,
            // filters: [
            //     {
            //       text: 'Joe',
            //       value: 'Joe',
            //     },
            //     {
            //       text: 'Jim',
            //       value: 'Jim',
            //     },
            //     {
            //       text: 'Submenu',
            //       value: 'Submenu',
            //       children: [
            //         {
            //           text: 'Green',
            //           value: 'Green',
            //         },
            //         {
            //           text: 'Black',
            //           value: 'Black',
            //         },
            //       ],
            //     },
            //   ]
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: (t, item) => item.createTime.toLocaleString(),
        },
        // {
        //     title: '按钮',
        //     dataIndex: 'optionButtonText',
        //     key: 'optionButtonText',
        //     width: 155,
        // },

        // {
        //     title: '已上架',
        //     dataIndex: 'published',
        //     key: 'published',
        //     render: (t, item) => item.enabled ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
        //     width: 80,
        // },

        // {
        //     title: '控制',
        //     key: 'action',
        //     fixed: 'right',
        //     width: 250,
        //     render: (_, item) => (
        //         <Space size="middle">
        //             <a onClick={() => this.rename(item.id)}>{"重命名"}</a>
        //             <a onClick={() => this.reeditButtonText(item.id)}>{"修改按钮"}</a>
        //             <a onClick={() => this.enable(item.id, item.enabled)}>{item.enabled ? "下架" : "上架"}</a>
        //             <a onClick={() => this.delete(item.id)}>{"删除"}</a>
        //         </Space>
        //     ),
        // },
    ];
    async fetchData() {
        const data = await api_listVoteData(this.props.voteId)
        const getOption = (optionId: number) => {
            for (let option of data.data.options) {
                if (option.id === optionId) {
                    return option
                }
            }
        }
        const items: DataType[] = []
        for (let vote of data.data.list) {
            const option = getOption(vote.voteOptionId)
            if (!option) { continue }
            const user = await getUser(vote.userId)
            items.push({
                id: vote.id,
                visualId: items.length,
                optionName: option.optionName,
                userId: vote.userId,
                userName: user ? user.username : "未知",
                nickName: user ? user.nickname : "未知",
                createTime: new Date(vote.createTime),
            })
            // this.setState({ items })
            // this.forceUpdate()

        }
        this.setState({ items })
    }



    async addItem() {
        const voteId: number = this.props.voteId
        await api_addVoteOption({
            voteId,
            optionName: `未命名选项-${new Date().valueOf()}`,
        })
        this.fetchData()
    }

    async rename(optionId: number) {
        const newName = prompt("新的选项名称");
        if (newName) {
            await api_renameVoteOption(optionId, newName)
        }
        this.fetchData()
    }
    async reeditButtonText(optionId: number) {
        const newText = prompt("新的按钮文字");
        if (newText) {
            await api_reeditVoteOptionButtonText(optionId, newText)
        }
        this.fetchData()
    }
    async enable(optionId: number, currentEnableState: boolean) {
        await api_enableVoteOption(optionId, !currentEnableState)
        this.fetchData()
    }
    async delete(optionId: number,) {
        if (confirm(`删除id为${optionId}的选项吗`)) {
            await api_removedVoteOption(optionId, "DELETE")
            this.fetchData()
        }
    }



}

