
import { apiRequest, api_checkUserTicket } from '@/api';
import { User, UserContext, UserContextDefaultValue } from '@/user';
import { Button, ConfigProvider, Input, Modal, Tabs } from 'antd';
import React, { PureComponent } from 'react';
import "./global.less";
import "./index.less";
// import "antd/dist/antd.less";
import { Guild } from './Project';
import zhCN from 'antd/es/locale/zh_CN';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

type State = {
  user?: User
  ticketInputValue?: string
  serverInputValue?: string
  checking?: boolean
}
class Page extends PureComponent<{}, State> {
  constructor({ }) {
    super({})
    this.state = {
      ticketInputValue: this.getPresetValues().key,
      serverInputValue: this.getPresetValues().serverURL,
    }
  }

  render() {
    return <ConfigProvider locale={zhCN}>
      <div>
        <Modal
          title={`登录(V${4.1})`}
          closable={false}
          maskClosable={false}
          open={!this.state.user}
          footer={null}
        >
          <div style={{ textAlign: "right" }}>
            <div>{"服务器地址"}</div>
            <Input style={{ marginBottom: 10 }} onChange={this.onServerInputChange.bind(this)} value={this.state.serverInputValue} />
            <div>{"秘钥"}</div>
            <Input style={{ marginBottom: 10 }} onChange={this.onTicketInputChange.bind(this)} value={this.state.ticketInputValue} />
            <Button type="primary" onClick={this.check.bind(this)} loading={this.state.checking}>{"进入"}</Button>
          </div>
        </Modal>
        {this.state.user ? <Guild user={this.state.user} manageURL={this.state.serverInputValue} /> : ""}
      </div>
    </ConfigProvider>
  }
  componentDidMount(): void {
    setTimeout(() => { document.title = "管理 - BookingSystem" }, 0);
    if (this.getPresetValues().auto) {
      this.check()
    }
  }
  getPresetValues() {
    const url = new URL(document.location.href)
    const serverURL = url.searchParams.get("server") || localStorage.getItem("bs_server") || undefined
    const key = url.searchParams.get("key") || localStorage.getItem("bs_ticket") || undefined
    const auto = url.searchParams.get("auto")|| undefined
    return {
      serverURL,
      key,
      auto,
    }
  }
  onServerInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ serverInputValue: event.target.value })
  }
  onTicketInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ticketInputValue: event.target.value })
  }
  async check() {
    this.setState({ checking: true })
    const ticket = this.state.ticketInputValue || ""
    const server = this.state.serverInputValue || ""
    //@ts-ignore
    globalThis.serverURL = server
    const result = await api_checkUserTicket(ticket)
    localStorage.setItem("bs_ticket", ticket)
    localStorage.setItem("bs_server", server)
    console.log(result)
    const user: User = {
      userLevel: result.level,
      userName: result.level,
      uid: result.level,
    }
    this.setState({ user })
    this.setState({ checking: false })
  }
}

export default Page
