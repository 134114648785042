import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"


export type FreshDataAPIs = APIExample<{
    "/fresh/guild": {
        method: "POST"
        toServer: {
            ticket: string
        },
        toClient: {}
    },
    "/fresh/user": {
        method: "POST"
        toServer: {
            ticket: string
            guildId: string
        },
        toClient: {}
    },
}>


export const api_freshGuild = async () => {
    return await apiRequest("/fresh/guild", {
        ticket: getTicket(),
    })
}
export const api_freshUser = async (guildId: string) => {
    return await apiRequest("/fresh/user", {
        ticket: getTicket(),
        guildId,
    })
}


