import { UserLevel } from "@/user/definition"
import { apiRequest, getTicket } from ".."
import { APIExample } from "../definition"
import { ListVoteDataAPIs } from "./data"
import { ListVoteOptionAPIs } from "./options"


type ListVoteDictAPIs = APIExample<{
    "/list/vote/dict": {
        method: "POST"
        toServer: {
            ticket: string
        },
        toClient: {
            data: {
                id: number;

                name: string
                description: string
                headPic: string
                startTime: string | Date
                endTime: string | Date
                endRegTime: string | Date

                createTime: Date | string;
                showOptionText: boolean;
                enabled: boolean;
                removed: boolean;
                data: any
            }[]
        }
    },
    "/add/vote/dict": {
        method: "POST"
        toServer: {
            ticket: string

            name: string
            headPic: string
            startTime: string | Date
            endTime: string | Date
            endRegTime: string | Date
        },
        toClient: {
            data: any
        }
    },
    "/edit/vote/dict": {
        method: "POST"
        toServer: {
            ticket: string
            dictId: number

            name?: string
            description?: string
            headPic?: string
            startTime?: string | Date
            endTime?: string | Date
            endRegTime?: string | Date
            enabled?: boolean
            showOptionText?: boolean
            removed?: boolean
        },
        toClient: {
            data: any
        }
    },
    "/edit/vote/config/VOTE_GEN_TITLE": {
        method: "POST"
        toServer: {
            ticket: string
            VOTE_GEN_TITLE: string
        },
        toClient: {
            data: any
        }
    },

}>

export type ListVoteAPIs = ListVoteDictAPIs & ListVoteOptionAPIs & ListVoteDataAPIs


export const api_listVoteDict = async () => {
    return await apiRequest("/list/vote/dict", {
        ticket: getTicket(),
    })
}

export const api_addVoteDict = async (data: {
    name: string
    headPic: string
    startTime: string | Date
    endTime: string | Date
    endRegTime: string | Date
}) => {
    return await apiRequest("/add/vote/dict", {
        ticket: getTicket(),
        ...data,
    })
}

export const api_editVoteDict = async (dictId: number, data: {
    name?: string
    description?: string
    headPic?: string
    startTime?: string | Date
    endTime?: string | Date
    endRegTime?: string | Date
}) => {
    return await apiRequest("/edit/vote/dict", {
        ticket: getTicket(),
        dictId,
        ...data,

    })
}



export const api_renameVoteDict = async (dictId: number, name: string) => {
    return await apiRequest("/edit/vote/dict", {
        ticket: getTicket(),
        dictId,
        name,

    })
}

export const api_enableVoteDict = async (dictId: number, enabled: boolean) => {
    return await apiRequest("/edit/vote/dict", {
        ticket: getTicket(),
        dictId,
        enabled,

    })
}

export const api_showOptionTextVoteDict = async (dictId: number, showOptionText: boolean) => {
    return await apiRequest("/edit/vote/dict", {
        ticket: getTicket(),
        dictId,
        showOptionText,

    })
}


export const api_removedVoteDict = async (dictId: number, conform: "DELETE") => {
    return await apiRequest("/edit/vote/dict", {
        ticket: getTicket(),
        dictId,
        removed: conform === "DELETE",

    })
}

export const api_editGenTitle = async (newTitle: string ) => {
    return await apiRequest("/edit/vote/config/VOTE_GEN_TITLE", {
        ticket: getTicket(),
        VOTE_GEN_TITLE: newTitle,
    })
}

