
import { DateTimePicker } from '@/component/prompt';
import { getUser } from '@/function/users/fetch';
import { getListVoiceChatBoard } from '@/function/VoiceChatBoard';
import { User } from '@/user';
import { Avatar, Button, Drawer, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PureComponent } from 'react';
import { VoiceChatBoardDetail } from './details';

type VoiceChatBoardProps = {
    shortName?: string
    user: User
}
type minUsers = {
    [userId: string]: {
        id: string;
        username: string;
        avatar: string;
    }
}
type State = {
    items?: VoiceChatBoardDataType[]
    manageingUserId?: string
    manageingShortName?: string
    choseingTimeRange?: boolean
    // users: minUsers
}
export interface VoiceChatBoardDataType {
    lineId: number;
    userId: string;
    // GuildnUserKey: string
    // key: React.Key;
    // username: string
    // nickname: string
    // avatar: string
    totalTime: number
    longestTime: number
    times: number

}


export class VoiceChatBoard extends PureComponent<VoiceChatBoardProps, State> {
    constructor(props: VoiceChatBoardProps) {
        super(props)
        this.state = {
            // users: {}
        }
    }

    startTime?: Date
    endTime?: Date
    render() {
        console.log("render")

        return <div>
            <Button type="primary" style={{ margin: 10 }} onClick={() => { this.forceUpdate() }}>{"forceUpdate"}</Button>
            <Button type="primary" style={{ margin: 10 }} onClick={this.chooseStartTime.bind(this)}>{"选择起始时间"}</Button>
            <Button type="primary" style={{ margin: 10 }} onClick={this.chooseEndTime.bind(this)}>{"选择结束时间"}</Button>

            <Table
                pagination={{ showSizeChanger: true }}
                columns={this.getColumns()}
                dataSource={this.state.items}
                scroll={{
                    x: 800
                }}
            />

            <Drawer
                title={`时间数据记录表(${this.state.manageingUserId})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingUserId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingUserId === "string"}>
                {this.state.manageingUserId && this.state.items ? <VoiceChatBoardDetail
                    manageingUserId={this.state.manageingUserId}
                    data={this.state.items}
                    startTime={this.startTime}
                    endTime={this.endTime}
                    user={this.props.user} /> : ""}
            </Drawer>


        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    getColumns(): ColumnsType<VoiceChatBoardDataType> {
        return [
            {
                title: '行',
                dataIndex: 'lineId',
                key: 'lineId',
                width: 48,
                // defaultSortOrder: 'descend',
                // sorter: (a, b) => a.lineId - b.lineId,
            },
            {
                title: '头像',
                className: "avatar",
                dataIndex: 'avatar',
                key: 'avatar',
                width: 60,
                render: (t, item) => {
                    console.log("renderavatar")
                    return this.users[item.userId] ? <Avatar src={<img src={this.users[item.userId].avatar} style={{ width: 32 }}
                        loading="lazy" referrerPolicy="no-referrer" />} /> : "/"
                }
            },
            {
                title: '用户名',
                dataIndex: 'username',
                key: 'username',
                render: (t, item) => this.users[item.userId] ? decodeURI(this.users[item.userId].username) : "/"

            },
            {
                title: '用户ID',
                dataIndex: 'userId',
                key: 'userId',
            },
            {
                title: '总时长',
                dataIndex: 'totalTime',
                key: 'totalTime',
                render: this.friendlyTime,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.totalTime - b.totalTime,
            },
            {
                title: '最长时间',
                dataIndex: 'longestTime',
                key: 'longestTime',
                render: this.friendlyTime,
            },
            {
                title: '进出次数',
                dataIndex: 'times',
                key: 'times',
                render: (t, item) => <>{t}{"次"}</>,
                filters: [
                    { text: '超过一次', value: 1, },
                    { text: '超过五次', value: 5, },
                ],
                onFilter: (value, record) => record.times > Number(value),
                sorter: (a, b) => a.times - b.times,
                sortDirections: ['descend'],
            },
            {
                title: '控制',
                key: 'action',
                fixed: 'right',
                width: 250,
                render: (_, item) => (
                    <Space size="middle">
                        <a onClick={() => this.showDetail(item.userId)}>{"查看数据"}</a>
                        {/* <a onClick={() => this.publish(item.activityId, item.published)}>{item.published ? "下架" : "上架"}</a> */}
                        {/* <a onClick={() => this.delete(item.activityId)}>{"删除"}</a> */}
                    </Space>
                ),
            },
        ];
    }
    async fetchData() {
        // const data = await api_listVoiceChatBoard("")
        const data = await getListVoiceChatBoard({
            startTime: this.startTime,
            endTime: this.endTime
        })
        console.log("getListVoiceChatBoard", "data", data)
        const items: VoiceChatBoardDataType[] = Array.from(data, (user, key) => {
            this.fetchUser(user.userId)

            let totalTime = 0, longestTime = 0, times = 0

            user.actions.sort((a, b) => new Date(a.kookTime).valueOf() - new Date(b.kookTime).valueOf())

            let lastActionTime = 0
            for (let action of user.actions) {
                if (action.action === "joined") {
                    lastActionTime = new Date(action.kookTime).valueOf()
                } else {
                    if (lastActionTime === 0) {
                        continue
                    } else {
                        const lastTime = new Date(action.kookTime).valueOf() - lastActionTime

                        console.log("lastTime", user.userId, lastTime)

                        times = times + 1
                        totalTime = totalTime + lastTime

                        if (lastTime > longestTime) {
                            longestTime = lastTime
                        }
                    }
                }
            }

            const result = {
                lineId: key + 1,
                userId: user.userId,
                totalTime: Math.ceil(totalTime / 1000),
                longestTime: Math.ceil(longestTime / 1000),
                times: times,
            }
            console.log("result", user.userId, result)

            return result
        })
        console.log("items", items)
        this.setState({ items })
    }

    friendlyTime(data: number) {
        const hour = Math.floor(data / 3600)
        const minute = Math.floor((data / 60) - 60 * hour)
        const second = data - (hour * 3600) - minute * 60
        return <>{`${hour}小时${minute}分钟${second}秒`}</>
    }
    showDetail(userId: string) {
        this.setState({
            manageingUserId: userId
        })
    }
    users: minUsers = {}
    async fetchUser(userId: string) {
        const data = await getUser(userId)
        console.log(data?.id, data)
        if (data) {
            this.users[data.id] = data
            // this.setState({
            //     "users": {
            //         ...this.state.users,
            //         [data.id]: data
            //     }
            // })
            this.forceUpdate()
        }
    }

    async chooseStartTime() {
        const time = await DateTimePicker("输入", this.startTime)
        console.log("chooseStartTime", time?.toISOString())
        this.startTime = time
        this.fetchData()
    }

    async chooseEndTime() {
        const time = await DateTimePicker("输入", this.endTime)
        console.log("chooseEndTime", time?.toISOString())
        this.endTime = time
        this.fetchData()
    }
}

