
import { api_addVoteDict, api_editGenTitle, api_editVoteDict, api_enableVoteDict, api_listVoteDict, api_removedVoteDict, api_renameVoteDict, api_showOptionTextVoteDict } from '@/api/vote';
import { DateTimePicker } from '@/component/prompt';
import { User } from '@/user';
import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Drawer, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PureComponent } from 'react';
import { ListVoteData } from './data';
import { ListVoteOptions } from './options';


type ListVoteDictProps = {
    shortName?: string
    user: User
}
type ListVoteDictState = {
    items?: DataType[]
    manageingVoteId?: number
    checkVoteId?: number
    manageingVoteName?: string
    checkVoteDict?: boolean
}
interface DataType {
    showOptionText: boolean;
    id: number;
    name: string
    description: string
    headPic: string
    startTime: string | Date
    endTime: string | Date
    endRegTime: string | Date
    createTime: Date;
    enabled: boolean;
    removed: boolean;
}


export class ListVoteDict extends PureComponent<ListVoteDictProps, ListVoteDictState> {
    constructor(props: ListVoteDictProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div className='Users'>
            <Button type="primary" style={{ margin: 10, }} onClick={this.addItem.bind(this)}>{"新增投票"}</Button>
            <Button type="primary" style={{ margin: 10, }} onClick={this.changeTitle.bind(this)}>{"修改title"}</Button>

            <Table
                pagination={{ showSizeChanger: true }}
                columns={this.columns}
                scroll={{ x: 2000, scrollToFirstRowOnChange: true }}
                dataSource={this.state.items} />

            <Drawer
                title={`场次管理(${this.state.manageingVoteName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={this.onManageClose.bind(this)}
                open={typeof this.state.manageingVoteId === "number"}>
                {this.state.manageingVoteId ? <ListVoteOptions shortName={this.state.manageingVoteName} voteId={this.state.manageingVoteId} user={this.props.user} /> : ""}
            </Drawer>
            <Drawer
                title={`数据分析(${this.state.manageingVoteName})`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={this.onCheckClose.bind(this)}
                open={typeof this.state.checkVoteId === "number"}>
                {this.state.checkVoteId ? <ListVoteData shortName={this.state.manageingVoteName} voteId={this.state.checkVoteId} user={this.props.user} /> : ""}
            </Drawer>
        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 48,
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: 155,
        },
        {
            title: '图片',
            dataIndex: 'headPic',
            key: 'headPic',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changePic(item.id)}>
                <Tooltip title={<div>
                    <div>{item.headPic}</div>
                    {"点击修改"}
                </div>}>{(String(item.headPic || "").substring(0, 20))}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 175,
        },
        {
            title: '描述文字',
            dataIndex: 'description',
            key: 'description',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeDescription(item.id)}>
                <Tooltip title={<div>
                    <div>{item.description}</div>
                    {"点击修改"}
                </div>}>{(String(item.description || "").substring(0, 20))}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("startTime", item)}>
                <Tooltip title="点击修改">{new Date(item.startTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("endTime", item)}>
                <Tooltip title="点击修改">{new Date(item.endTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: <Tooltip title={"截止接受登记时间"}>{'登记截止时间'}</Tooltip>,
            dataIndex: 'regEndTime',
            key: 'regEndTime',
            render: (t, item) => <span style={{ cursor: "pointer" }} onClick={() => this.changeTime("endRegTime", item)}>
                <Tooltip title="点击修改">{new Date(item.endRegTime).toLocaleString()}<EditOutlined style={{ marginLeft: 5, color: "#40a9ff" }} /></Tooltip>
            </span>,
            width: 155,
        },
        {
            title: '子项',
            dataIndex: 'SeatCount',
            key: 'SeatCount',
            render: (t, item) => <>
                <Tooltip title="点击管理选项"><Button type="dashed" onClick={() => this.manageOptions(item.id, item.name)}>{`选项`}</Button></Tooltip>
                <Tooltip title="点击查看和管理投票情况"><Button type="dashed" onClick={() => this.checkVoteData(item.id, item.name)}>{`数据`}</Button></Tooltip>

            </>,
            width: 200,
        },
        {
            title: '已上架',
            dataIndex: 'published',
            key: 'published',
            render: (t, item) => item.enabled ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
            width: 80,
        },
        {
            title: '显示选项内容',
            dataIndex: 'published',
            key: 'published',
            render: (t, item) => item.showOptionText ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
            width: 80,
        },

        {
            title: '控制',
            key: 'action',
            fixed: 'right',
            width: 290,
            render: (_, item) => (
                <Space size="middle">
                    <a onClick={() => this.rename(item.id)}>{"重命名"}</a>
                    <a onClick={() => this.enable(item.id, item.enabled)}>{item.enabled ? "下架" : "上架"}</a>
                    <a onClick={() => this.showOptionText(item.id, item.showOptionText)}>{item.showOptionText ? "隐藏选项内容" : "显示选项内容"}</a>
                    <a onClick={() => this.delete(item.id)}>{"删除"}</a>
                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listVoteDict()
        const items: DataType[] = Array.from(data.data, (item, key) => {
            return {
                ...item,
                createTime: new Date(item.createTime),
                lineId: key + 1,
            }
        })
        this.setState({ items })
    }

    async changePic(voteId: number) {
        const newHeadPic = prompt("新的图片URL");
        if (newHeadPic) {
            await api_editVoteDict(voteId, {
                headPic: newHeadPic
            })
        }
        this.fetchData()
    }
    async changeDescription(voteId: number) {
        const newDescription = prompt("新的description");
        if (newDescription) {
            await api_editVoteDict(voteId, {
                description: newDescription
            })
        }
        this.fetchData()
    }
    async changeTime(type: "startTime" | "endTime" | "endRegTime", item: DataType) {
        let voteId = item.id
        let describe = "未知文案"
        let time = new Date()
        switch (type) {
            case "startTime":
                describe = `修改ID为 ${item.id} 的 开始时间`
                time = new Date(item.startTime)
                break;
            case "endTime":
                describe = `修改ID为 ${item.id} 的 结束时间`
                time = new Date(item.endTime)
                break;
            case "endRegTime":
                describe = `修改ID为 ${item.id} 的 登记截止时间`
                time = new Date(item.endRegTime)
                break;
            default:
                break;
        }

        const result = await DateTimePicker(describe, time, `该场次的所有记录中的时间都会随之改变`)
        if (result) {
            await api_editVoteDict(voteId, { [type]: result, })
            this.fetchData()

        }

    }

    async addItem() {
        await api_addVoteDict({
            name: `未命名活动-${new Date().valueOf()}`,
            headPic: "",
            startTime: new Date().toISOString(),
            endTime: new Date(new Date().valueOf() + 1000 * 3600 * 24).toISOString(),
            endRegTime: new Date(new Date().valueOf() + 1000 * 3600 * 1).toISOString(),
        })
        this.fetchData()
    }
    async changeTitle() {
        const newName = prompt("新的卡片消息title");
        if (newName) {
            await api_editGenTitle(newName)
        }
        this.fetchData()
    }

    async rename(voteId: number) {
        const newName = prompt("新的投票名称");
        if (newName) {
            await api_renameVoteDict(voteId, newName)
        }
        this.fetchData()
    }
    async enable(voteId: number, currentEnableState: boolean) {
        await api_enableVoteDict(voteId, !currentEnableState)
        this.fetchData()
    }
    async showOptionText(voteId: number, currentShowState: boolean) {
        await api_showOptionTextVoteDict(voteId, !currentShowState)
        this.fetchData()
    }
    async delete(voteId: number,) {
        if (confirm(`删除id为${voteId}的投票吗`)) {
            await api_removedVoteDict(voteId, "DELETE")
            this.fetchData()
        }
    }


    async manageOptions(voteId: number, name: string) {
        this.fetchData()
        this.setState({
            manageingVoteId: voteId,
            manageingVoteName: name
        })
    }
    async checkVoteData(voteId: number, name: string) {
        this.fetchData()
        this.setState({
            checkVoteId: voteId,
            manageingVoteName: name
        })
    }
    async onManageClose() {
        this.fetchData()
        this.setState({ manageingVoteId: undefined })
    }
    async onCheckClose() {
        this.fetchData()
        this.setState({ checkVoteId: undefined })
    }

}

