import { message } from "antd";
import { API, APIs, APIsConfiguration } from "./definition"


type apiInputGET<Path extends keyof APIs> = [path: Path,]
type apiInputPOST<Path extends keyof APIs, oneAPI = APIs[Path]> = [
    path: Path,
    data: oneAPI extends { toServer: infer R; } ? R : undefined
]
export const apiRequest = async <Path extends keyof APIs, oneAPI = APIs[Path],>
    (...arg: oneAPI extends { toServer: Object } ? apiInputPOST<Path> : apiInputGET<Path>): Promise<APIs[Path]["toClient"]> => {
    const path = arg[0], data = arg[1]

    // const config = APIsConfiguration[path]
    // if (!config) {
        // throw "未找到 config"
    // }
    let body: string | undefined = JSON.stringify(data)
    // if (config.method === "GET") { body = undefined }

    const urlPrefix = serverURL || "http://localhost:3000"
    const data2 = await fetch(`${urlPrefix}${path}`, {
        // method: config.method,
        method: "POST",
        body,
    })

    const json = await data2.json()
    const response = json as API<APIs[Path]["toClient"]>
    if (response.success) {
        return response.data
    } else {
        message.error(response.message || '服务器请求失败');
        throw response
    }
}

export const getTicket = () => {
    // return ""
    return localStorage.getItem("bs_ticket") || "undefined"
}
export const api_checkUserTicket = async (ticket: string) => {
    return await apiRequest("/user/check", { ticket })
}


// export const api_listProject = async () => {
//     return await apiRequest("/project/list", { ticket: getTicket() })
// }
// export const api_addProject = async (name: string) => {
//     return await apiRequest("/project/add", {
//         ticket: getTicket(),
//         name
//     })
// }
// export const api_deleteProject = async (id: number) => {
//     return await apiRequest("/project/delete", {
//         ticket: getTicket(),
//         id
//     })
// }
// export const api_renameProject = async (id: number,newName:string) => {
//     return await apiRequest("/project/rename", {
//         ticket: getTicket(),
//         projectId:id,
//         newName,
//     })
// }


// export const api_Anyway = async () => {
// //     return await apiRequest("/anyway/2333",)
// }