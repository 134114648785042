
import { api_addVoteOption, api_enableVoteOption, api_listVoteOption, api_reeditVoteOptionButtonText, api_removedVoteOption, api_renameVoteOption } from '@/api/vote/options';
import { User } from '@/user';
import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PureComponent } from 'react';


type ListVoteOptionsProps = {
    shortName?: string
    user: User
    voteId: number
}
type ListVoteOptionsState = {
    items?: DataType[]
    manageingVoteId?: number
    manageingVoteName?: string
    checkVoteDict?: boolean
}
interface DataType {
    id: number;
    optionName: string
    optionButtonText: string
    createTime: Date;
    enabled: boolean;
    removed: boolean;
}


export class ListVoteOptions extends PureComponent<ListVoteOptionsProps, ListVoteOptionsState> {
    constructor(props: ListVoteOptionsProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <div className='Users'>
            <Button type="primary" style={{ margin: 10, }} onClick={this.addItem.bind(this)}>{"新增"}</Button>
            <Table
                pagination={{ showSizeChanger: true }}
                columns={this.columns}
                dataSource={this.state.items} />

        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 48,
        },
        {
            title: '选项内容',
            dataIndex: 'optionName',
            key: 'optionName',
            width: 155,
        },
      {
            title: '按钮',
            dataIndex: 'optionButtonText',
            key: 'optionButtonText',
            width: 155,
        },

        {
            title: '已上架',
            dataIndex: 'published',
            key: 'published',
            render: (t, item) => item.enabled ? <Tag color="green">{"是"}</Tag> : <Tag color="red">{"否"}</Tag>,
            width: 80,
        },

        {
            title: '控制',
            key: 'action',
            fixed: 'right',
            width: 250,
            render: (_, item) => (
                <Space size="middle">
                    <a onClick={() => this.rename(item.id)}>{"重命名"}</a>
                    <a onClick={() => this.reeditButtonText(item.id)}>{"修改按钮"}</a>
                    <a onClick={() => this.enable(item.id, item.enabled)}>{item.enabled ? "下架" : "上架"}</a>
                    <a onClick={() => this.delete(item.id)}>{"删除"}</a>
                </Space>
            ),
        },
    ];
    async fetchData() {
        const data = await api_listVoteOption(this.props.voteId)
        const items: DataType[] = Array.from(data.data, (item, key) => {
            return {
                ...item,
                createTime: new Date(item.createTime),
                lineId: key + 1,
            }
        })
        this.setState({ items })
    }



    async addItem() {
        const voteId: number = this.props.voteId
        await api_addVoteOption({
            voteId,
            optionName: `未命名选项-${new Date().valueOf()}`,
        })
        this.fetchData()
    }

    async rename(optionId: number) {
        const newName = prompt("新的选项名称");
        if (newName) {
            await api_renameVoteOption(optionId, newName)
        }
        this.fetchData()
    }
    async reeditButtonText(optionId: number) {
        const newText = prompt("新的按钮文字");
        if (newText) {
            await api_reeditVoteOptionButtonText(optionId, newText)
        }
        this.fetchData()
    }
    async enable(optionId: number, currentEnableState: boolean) {
        await api_enableVoteOption(optionId, !currentEnableState)
        this.fetchData()
    }
    async delete(optionId: number,) {
        if (confirm(`删除id为${optionId}的选项吗`)) {
            await api_removedVoteOption(optionId, "DELETE")
            this.fetchData()
        }
    }



}

