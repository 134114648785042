
// import { api_addProject, api_deleteProject, api_listProject, api_renameProject } from '@/api';
// import prompt from 'antd-prompt';
import { api_freshGuild } from '@/api/children/freshData';
import { api_listGuilds } from '@/api/children/listData';
import { User } from '@/user';
import { Button, Input, message, Modal, notification, Radio, Switch, Tabs } from 'antd';
// import 'antd/dist/antd.min.css';
import React, { PureComponent } from 'react';
import { Users } from '../User';
// import { ViewActivitys } from '../Activity/viewer';
import "./index.less";

type viewMode = "manage" | "view" | "debug"

type ProjectProps = {
  manageURL: string | undefined
  user: User
}
type ProjectState = {
  viewMode: viewMode
  adding?: string
  items?: {
    label: string | React.ReactNode,
    children: React.ReactNode,
    key: string
  }[]
}


export class Guild extends PureComponent<ProjectProps, ProjectState> {
  constructor(props: ProjectProps) {
    super(props)
    const userLevel = this.props.user.userLevel
    const defaultMode = userLevel === "viewer" ? "view" : "manage"
    const localMode = defaultMode
    // const localMode = localStorage.getItem("viewMode") as viewMode

    console.log("localMode", localMode || defaultMode)
    this.state = {
      viewMode: localMode || defaultMode
    }
  }

  render() {
    return <div>
      <Tabs
        tabBarExtraContent={this.renderSlot()}
        type={"card"}
        items={this.state.items}
      />
    </div>

  }
  renderSlot(): React.ReactNode {
    return <div style={{ marginRight: 4 }}>
      <Button onClick={this.onFresh.bind(this)}>
        {"下载服务器数据"}
      </Button>

    </div>
  }
  async componentDidMount() {
    const manageURL = new URL(this.props.manageURL || "http://undef")
    notification.open({
      message: `正在管理 ${manageURL.host}`,
      description: `当前登身份为 ${this.props.user.userName}`,
    });
    // message.success(, 1);
    this.fetchData()
  }
  async fetchData() {
    const data = await api_listGuilds()
    const items = Array.from(data.data, (guild) => {
      return {
        label: <div>{`${decodeURI(guild.name)}(ID:${guild.id})`}</div>,
        children: <Users
          shortName={`${decodeURI(guild.name)} 中的用户`}
          guildId={guild.id}
          user={this.props.user}
        />,
        key: String(guild.id)
      }
    })
    if (items.length === 0) {
      message.error("没有服务器数据")
      this.onFresh()
    }
    this.setState({
      items
    })
  }
  async onFresh() {
    message.info("正在下载服务器数据")
    await api_freshGuild()
    message.info("数据下载完成")
    await this.fetchData()
  }


}

